import { Box, Button, Container, Stack, Typography } from "@mui/material";
import { Header, HeaderOffset, HeroHeaderContainer, HeaderItem } from "@cm-websites/common";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Grid from "@mui/material/Unstable_Grid2";
import Image from "next/image";
import Mood from "../../../public/images/hero-header/krimidinner-foerdern-feiern-feuern.jpg";
import NextLink from "next/link";
import React from "react";

type HeroHeaderProps = { navItems: HeaderItem[] };

export const HeroHeader: React.FC<HeroHeaderProps> = ({ navItems = [] }) => (
  <HeroHeaderContainer backgroundColor="#fbe7fd" id="frontpage-hero-header" sticky={false}>
    <Header items={navItems} />
    <HeaderOffset />
    <Container
      maxWidth="xl"
      sx={{
        overflow: { xs: "hidden", lg: "unset" },
        pb: { xs: 4, sm: 8, lg: 6 },
        pt: { xs: 0, sm: 8, lg: 3 },
      }}
    >
      <Grid
        alignItems="center"
        container
        spacing={{ xs: 2, sm: 4, lg: 8 }}
        direction={{ xs: "column-reverse", sm: "row" }}
      >
        <Grid xs={12} sm={7} lg={5}>
          <Box>
            <Typography
              variant="h1"
              sx={{ pb: { xs: 3, lg: 4 }, textAlign: { xs: "center", sm: "left" } }}
            >
              <Typography component="span" variant="overline" sx={{ display: "block" }}>
                Das Krimidinner für Zuhause 
              </Typography>
              Fördern, feiern, feuern
            </Typography>
            <Typography variant="body1" paragraph sx={{ textAlign: { xs: "center", sm: "left" } }}>
              Der alltägliche Büro-Wahnsinn! Freitagnachmittag, der Feierabend ist zum Greifen nahe. Doch
              Hans Sachs, Urgestein der FIASKO-Versicherung, hat anlässlich seines Geburtstags zu einem
              Umtrunk im Büro eingeladen. Was als harmlose Feier beginnt, nimmt im Laufe des Abends eine
              dramatische Wendung, als man ausgerechnet das Geburtstagskind tot in seinem Büro findet.
            </Typography>

            {/* <Typography variant="body1" paragraph sx={{ textAlign: { xs: "center", sm: "left" } }}>
              Bestellungen bis einschließlich 17.12.2023 kommen innerhalb Deutschlands noch rechtzeitig
              zu Weihnachten an.
            </Typography> */}

            <Stack
              alignItems="center"
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 0.5, sm: 1 }}
              sx={{ pt: { xs: 1, sm: 3 } }}
            >
              <Button
                endIcon={<ChevronRightIcon />}
                href="/krimidinner/foerdern-feiern-feuern"
                LinkComponent={NextLink}
                sx={{ width: { xs: "100%", sm: "unset" } }}
                variant="contained"
              >
                Fall entdecken
              </Button>
              <Button
                href="/wie-funktioniert-ein-krimidinner-fuer-zuhause"
                LinkComponent={NextLink}
                sx={{ width: { xs: "100%", sm: "unset" } }}
                variant="text"
              >
                Was ist das?
              </Button>
            </Stack>
          </Box>
        </Grid>
        <Grid xs={12} sm={5} lg={7}>
          <Box
            sx={{
              "& > img": {
                borderRadius: "16px",
                overflow: "hidden",
                width: "100%",
                height: "auto",
              },
              transform: { xs: "scale(1.1)", sm: "scale(1.4)", md: "scale(1.3)", lg: "scale(1.0)" },
              borderRadius: "16px",
              display: "flex",
              flex: "0 0 100%",
              mr: { xs: 0, sm: -5, md: -5, lg: 4 },
              overflow: "hidden",
            }}
          >
            <Image
              alt="Fördern, feiern, feuern – Das Krimidinner für Zuhause"
              sizes={`
                (max-width: 767px) 80vw,
                (min-width: 768px) 28vw,
              `}
              placeholder="blur"
              priority
              src={Mood}
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  </HeroHeaderContainer>
);
